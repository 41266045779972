<template>
	<div class="pui-form">
		<imofalformsgeneral-modals :modelName="modelName"></imofalformsgeneral-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<component :is="this.componentName" :pk="this.compPk" :method="this.methodComponent"></component>
				</v-col>
			</v-row>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import ImoFalFormsGeneralActions from './ImoFalFormsGeneralActions';
import ImoFalFormsGeneralModals from './ImoFalFormsGeneralModals';

export default {
	name: 'imofalformsgeneral-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'imofalformsgeneral-modals': ImoFalFormsGeneralModals
	},
	data() {
		return {
			modelName: 'imofalformsgeneral',
			actions: ImoFalFormsGeneralActions.formactions,
			methodComponent: 'update',
			componentName: null,
			compPk: null,
			componentLoaded: null
		};
	},

	mounted() {
		this.getPkFromUrl();
		this.loadInfo();
		this.getEncodedPk();
	},
	methods: {
		afterGetData() {
			if (this.$router.currentRoute.path.startsWith('/portcalls/')) {
				localStorage.setItem('tabFromAction', 'imofalformsgeneral');
			}
		},
		getViewData(data) {
			if (!this.modelDependency) {
				return;
			}

			this.$puiRequests.postRequest(
				this.modelDependency.url.list,
				data,
				(response) => {
					if (response && response.data && response.data.data && response.data.data.length > 0) {
						this.componentLoaded = response.data.data[0].model;
						this.componentName = response.data.data[0].formcomponent;
					}
				},
				(err) => {
					if (err) {
						this.$puiNotify.error(this.$t('form.errorheader'));
					}
				}
			);
		},
		getPkFromUrl() {
			let info = null;
			try {
				info = this.$puiUtils.getPathModelMethodPk(this.$router, this.modelName);
			} catch (e) {
				// do nothing
			}

			if (info) {
				this.pathModelPk = info.pk;
			} else if (this.modelPk) {
				this.pathModelPk = this.modelPk;
			}
		},
		loadInfo() {
			if (this.pathModelPk && this.modelName) {
				const filter = this.createFilter(this.pathModelPk);

				this.getViewData(filter);
			}
		},
		createFilter(pk) {
			const searchRequest = {
				filter: { groupOp: 'and', rules: [], groups: [] }
			};

			for (var key in pk) {
				searchRequest.filter.rules.push({ field: key, op: 'eq', data: pk[key] });
			}
			return searchRequest;
		},
		getEncodedPk() {
			var path = this.$router.currentRoute.path;

			var routeTokens = path.split('/');
			var tokens = [];

			for (let i = 0, length = routeTokens.length; i < length; i++) {
				if (routeTokens[i] !== '') {
					tokens.push(routeTokens[i]);
				}
			}

			var position = null;
			for (let i = 0, length = tokens.length; i < length; i++) {
				const token = tokens[i];
				if (token === this.modelName) {
					position = i;

					break;
				}
			}

			if (position == null) {
				return null;
			}

			this.compPk = tokens[position + 2];
		}
	}
};
</script>
